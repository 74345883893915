.block-container.block-link + .block-container.block-link{
  padding-top: 8px;
}
.block-container.block-link a{
  text-decoration: none;
}
.DraftEditor-editorContainer .align-center > div {
  text-align: center;
}
.DraftEditor-editorContainer .align-left > div {
  text-align: left;
}
.DraftEditor-editorContainer .align-right > div {
  text-align: right;
}
.DraftEditor-editorContainer .align-justify > div {
  text-align: justify;
}
.DraftEditor-editorContainer blockquote{
  padding: 4px 8px;
  border-left: 3px solid #DDDDDD;
  opacity: 0.8;
}
.DraftEditor-editorContainer .public-DraftEditor-content,
.public-DraftEditorPlaceholder-root {
  font-size: 1rem;
  line-height: 1.4;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.DraftEditor-editorContainer ul, .DraftEditor-editorContainer ol {
  margin: 4px 0;
}
.DraftEditor-editorContainer .public-DraftStyleDefault-pre {
  border-radius: 8px;
  padding: 2px 8px;
  background-color: #EEE;
  font-size: 14px;
  margin: 4px 0;
}
.DraftEditor-editorContainer .public-DraftStyleDefault-pre pre{
  background-color: #FCFCFC;
  border-radius: 4px;
  margin: 4px 0;
  padding: 2px 8px;
}
#anychat-container #anychat-launcher-frame {
  right: 10px;
  bottom: 10px;
}
#anychat-container #anychat-chatbox-container{
  bottom: 20px;
  right: 20px;
}